import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Success from './Success';

function App() {
  const [isAuthenticated, setAuth] = useState(false);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          <Route path="/success" element={isAuthenticated ? <Success /> : <Navigate replace to="/login" />} />
          <Route path="/" element={<Navigate replace to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
