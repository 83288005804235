import React, { useState } from 'react';
import './Success.css';

function Success() {
  const [textInput, setTextInput] = useState('');
  const [analysisResult, setAnalysisResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Analyze'); // New state for button text

  const handleAnalyzeClick = async () => {
    setIsLoading(true);
    setButtonText('Analyzing...'); // Change button text
    const analysisURL = process.env.REACT_APP_ANALYSIS_URL;
    const response = await fetch(analysisURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: textInput }),
    });
    const data = await response.json();
    setAnalysisResult(data.result);
    setIsLoading(false);
    setButtonText('Analyze'); // Reset button text
  };

  const handleCopyResults = () => {
    navigator.clipboard.writeText(analysisResult);
  };

  const handleNewNote = () => {
    setTextInput('');
    setAnalysisResult('');
    setIsLoading(false);
    setButtonText('Analyze'); // Reset button text
  };

  return (
    <div className="success-container">
      <p className="disclaimer"> Disclaimer: This is for demonstration purposes only. Do not paste any confidential data here. </p>
      <textarea
        className="text-area"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        placeholder="Enter note here..."
        disabled={isLoading || analysisResult !== ''}
      />
      {!analysisResult && !isLoading && (
        <button className="button analyze-button" onClick={handleAnalyzeClick} disabled={isLoading}>
          {buttonText}
        </button>
      )}
      {analysisResult && (
        <div className="results-container">
          <p className="results-text">{analysisResult}</p>
          <div className="button-group">
            <button className="button" onClick={handleCopyResults}>Copy Results</button>
            <button className="button" onClick={handleNewNote}>New Note</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Success;
