import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login({ setAuth }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/login', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const { access_token } = await response.json();
        localStorage.setItem('access_token', access_token); // Store the token 
        setAuth(true);
        navigate("/success");
      } else {
        // Handle unsuccessful login, e.g., show error message
        const errorData = await response.json();
        alert(errorData.message || "Login failed!");
        setAuth(false);
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("Login request failed");
      setAuth(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <input type="text" className="login-input" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="password" className="login-input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button className="login-button" type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
